// Entry point for the build script in your package.json
import Rails from "@rails/ujs"
// import Turbolinks from "turbolinks"
// import * as ActiveStorage from "@rails/activestorage"
// import "channels"

import * as bootstrap from "bootstrap"
import './add_jquery'
import 'jquery-ui/ui/widgets/datepicker'

import ApexCharts from 'apexcharts'
import Select2 from "select2"
window.ApexCharts = ApexCharts
window.Select2 = Select2

import "../../lib/assets/js/main"
import "../../lib/assets/js/defaultmenu.min"
import "../../lib/assets/js/validation"
// import "../../lib/assets/js/date&time_pickers"
import LocalTime from "local-time"
LocalTime.start()

Rails.start()
// Turbolinks.start()
// ActiveStorage.start()
